import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withCustomerContext from '../../withCustomerContext';
import Layout from '../../layouts';
import { colors, Container as ContainerV3 } from '../../components/home/v3/styledComponents';
import MenuBar from '../../components/home/MenuBar';
import MyAccountContainer from '../../components/MyAccount/MyAccount';
import Footer from '../../components/home/v3/Footer';

const Container = styled(ContainerV3)`
  background-color: ${colors.white};
`;

class MyAccount extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoginPopupOpened: false,
    };
    this.openLoginPopup = this.openLoginPopup.bind(this);
  }

  componentDidMount() {
    const { customerContext: { fetchCustomer } } = this.props;
    fetchCustomer().then((customer) => { if (!customer) this.openLoginPopup(); });
  }

  openLoginPopup() {
    this.setState({ isLoginPopupOpened: true });
  }

  render() {
    const { isLoginPopupOpened } = this.state;
    return (
      <Layout routeSlug="MyAccount">
        <Container>
          <MenuBar isLoginPopupOpened={isLoginPopupOpened} />
          <MyAccountContainer selectedAccountCategory="MyAccount" />
          <Footer />
        </Container>
      </Layout>
    );
  }
}

MyAccount.propTypes = {
  customerContext: PropTypes.shape({
    fetchCustomer: PropTypes.func,
  }).isRequired,
};

export default withCustomerContext(MyAccount);
